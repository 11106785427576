import axios from "axios";

const baseUrl = process.env.VUE_APP_API_URL;

const apiClient = axios.create({
  baseURL: baseUrl,
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
  },
});

const fileUploadClient = axios.create({
  baseURL: baseUrl,
  timeout: 60000,
});

export default {
  async submitForm(data, uuid) {
    try {
      const response = await apiClient.post(`/post_form/${uuid}`, data.scorad_form, {
        headers: {
          'Content-Type': 'application/json'  // Ensure the data is sent as JSON
        }
      });
      return response.data;      
      // const response = await apiClient.post(`/post_form/${uuid}`, data);
      // return response.data;
    } catch (error) {
      if (error.response) {
        console.error("Error submitting form:", error.response.data);
      } else {
        console.error("Error submitting form:", error.message);
      }
      throw error;
    }
  },
  async getUuidStatus(uuid) {
    try {
      const response = await apiClient.get(`/get_status/${uuid}`);
      return response.data.status;
    } catch (error) {
      console.error("Error getting UUID status:", error);
      return "error";
    }
  },

  async getImageStatus(uuid, image_id) {
    try {
      const response = await apiClient.get(
        `/get_image_status/${uuid}/${image_id}`
      );
      return response.data.status;
    } catch (error) {
      console.error("Error getting image status:", error);
      return "error";
    }
  },

  async pollImageStatus(uuid, image_id, maxAttempts = 20) {
    try {
      let attempts = 0;
      let status = await this.getImageStatus(uuid, image_id);
      while ((status === "pending" || status === "processing") && attempts < maxAttempts) {
        await new Promise((resolve) => setTimeout(resolve, 5000));
        status = await this.getImageStatus(uuid, image_id);
        attempts++;
      }
      if (attempts === maxAttempts) {
        console.error("Max polling attempts reached.");
        return "timeout";
      }      
      return status;
    } catch (error) {
      console.error("Error polling image status:", error);
      throw error;
    }
  },

  async uploadImage(uuid, imageFile, region, image_id, onProgress, retries = 3) {
    const formData = new FormData();
    formData.append("image", imageFile);

    const partParam = encodeURIComponent(region);
    const idParam = encodeURIComponent(image_id);
    const url = `/post_image/${uuid}?part=${partParam}&id=${idParam}`;

    try {
      const response = await fileUploadClient.post(url, formData, {
        onUploadProgress: (progressEvent) => {
          const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          onProgress(progress);
        },
      });
      return response.data;
    } catch (error) {
      if (retries > 0) {
        console.warn(`Retrying... (${3 - retries + 1})`, error);
        return this.uploadImage(uuid, imageFile, region, image_id, onProgress, retries - 1);
      } else {
        console.error("Upload failed after multiple attempts:", error);
        throw error;
      }
    }
  },

  async cancelUpload(uuid, region, image_id) {
    const partParam = encodeURIComponent(region);
    const idParam = encodeURIComponent(image_id);
    const url = `/cancel_upload/${uuid}?part=${partParam}&id=${idParam}`;
    try {
      const response = await apiClient.delete(url);
      return response.data;
    } catch (error) {
      console.error("Error cancelling upload:", error);
      throw error;
    }
  },

  // async setOverview(uuid, region, image_id) {
  //   const partParam = encodeURIComponent(region);
  //   const idParam = encodeURIComponent(image_id);
  //   const url = `/set_overview_image/${uuid}?part=${partParam}&id=${idParam}`;
  //   return apiClient.put(url);
  // },

  async getResults(uuid) {
    try {
      const response = await apiClient.get(`/get_form/${uuid}`);
      return response.data;
    } catch (error) {
      console.error("Error getting results:", error);
      throw error;
    }
  },

  // returns a dictionary of lists of image ids for each region
  async getImageIds(uuid) {
    try {
      const response = await apiClient.get(`/get_image_ids/${uuid}`);
      return response.data;
    } catch (error) {
      console.error("Error getting image IDs:", error);
      throw error;
    }
  },

  async getImagePreview(uuid, image_id) {
    const response = await apiClient.get(
      `/get_image_preview/${uuid}/${image_id}`
    );
    const base64String = response.data;
    const src = "data:image/png;base64," + base64String;
    return src;
  },

  async getImageData(uuid, image_id) {
    try {
      const response = await apiClient.get(`/get_image_data/${uuid}/${image_id}`);
      return response.data;
    } catch (error) {
      console.error("Error getting image data:", error);
      throw error;
    }
  },

  // TODO: check this later
  async getImage(uuid, name) {
    try {
      const response = await apiClient.get(`/get_image/${uuid}/${name}`);
      return "data:image/png;base64," + response.data;
    } catch (error) {
      console.error("Error getting image:", error);
      throw error;
    }
  },

  // add optional message
  async getNewFormId(message = "") {
    try {
      const encodedMessage = encodeURIComponent(message);
      const response = await apiClient.get(`/get_url`, {
        params: { message: encodedMessage },
      });
      return response.data.id;
    } catch (error) {
      console.error("Error getting new form ID:", error);
      throw error;
    }
  },
};
